// Overrides
$primary: $primary-color;
$slick-font-path: "../fonts/slick/";

// bulma
@import "../../../node_modules/bulma/bulma";
// slick
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
// lightgallery
@import "../../../node_modules/lightgallery/dist/css/lightgallery.scss";
@import "../../../node_modules/lightgallery/dist/css/lg-transitions.scss";
// Rest
@import "flaticon";
@import "frontend/nav";

html{
  overflow-y: auto;
  font-size: 16px;
}
a{
  transition: all 0.3s ease;
}
hr{
  margin: 10px 0 20px;
}
.content article a:not(.button):visited{
  color: inherit;
}
.button{
  transition: all 0.2s ease;
}
.no-mg-bt{
  margin-bottom: 0;
}
.hero{
  position: relative;
  margin-top: 50px;
  .title{
    text-shadow: 0 0 20px $grey-darker;
    font-weight: 500;
  }
  .subtitle{
    text-shadow: 0 0 10px $grey-darker;
  }
}
.pt--64{
  padding-top: 0;
}
@media screen and (min-width: 769px){
  .hero.is-mysize .hero-body {
      padding-bottom: 110px;
      padding-top: 110px;
  }
  .column.is-fifth{
    width: 20%;
  }
  .pt--64{
    padding-top: 64px;
  }
}
.mt-button-content{
  margin-top: 6px;
  .icon{
    font-size: 18px;
  }
}
.hero-fader{
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  overflow: hidden;
  img{
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: none;
    height: 100%;
    width: auto;
    min-width: 100%;
  }
}
.team{
  .content{
    background: $white;
    box-shadow: $shadow-depth-1;
    padding: 30px 20px;
    transition: all 0.4s ease;
    border-radius: 3px;
    &:hover{
      box-shadow: $shadow-depth-3;
    }
  }
  .team-img{
    width: 100%;
    margin-bottom: 20px;
    img{
      height: 128px;
      width: 128px;
      border-radius: 50%;
      display: inline-block;
      border: 3px solid $grey-lighter;
    }
  }
}
.mt--10{
  margin-top: 10px;
}
.mt--20{
  margin-top: 20px;
}
.mb--20{
  margin-bottom: 20px !important;
}
.hero-body{
  padding: 80px 20px;
}
.container.is-fluid{
  margin: 0;
  padding: 0 20px;
  @media only screen and (min-width: 1023px) {
    margin: 0;
    padding: 0 30px;
  }
}
.content ul{
  list-style: circle;
}
.color-is-primary{
  color: $primary;
}
.pt--25{
  padding-top: 25px;
}
.has-floral-bg{
  background-image: url('/main/img/content_bg.jpg');
  background-repeat: repeat;
  background-size: 50%;
  background-position: center;
}
.has-white-bg{
  background-color: #fbfbfb;
}
.has-grey-bg{
  background-color: lighten($grey-lighter, 10%);
}
.event-item{
  border-top: 1px dashed #e8e8e8;
  padding: 15px 0;
}
.has-underline-center:after{
  position: relative;
  left: calc(50% - 33px);
}
.event-item.event-item-first{
  border-top: 0;
  padding-top: 5px;
}
.section-before-footer{
  background: $grey-darker;
  color: $grey-lighter;
  padding: 60px 20px;
  @media only screen and (min-width: 1023px) {
    padding: 80px 30px;
  }
  @media screen and (max-width: 769px){
    text-align: center;
  }
  a{
    display: block;
    &:hover{
      color: lighten($primary, 7%);
    }
  }
  h4{
    font-weight: bold;
    color: $grey;
  }
}
.footer{
  padding: 30px 20px;
  background: #2f2f2f;
  p, strong{
    color: $grey-lighter;
  }
}
.is-single-card .card{
  box-shadow: $shadow-depth-1;
  &:hover{
    box-shadow: $shadow-depth-3;
  }
}
.is-uppercase{
  text-transform: uppercase;
}
.sr .reveal-this{
  visibility: hidden;
}
.has-vline::before{
  height: 100px;
  width: 1px;
  margin: 0 auto;
  content: '';
  position: absolute;
  background: $grey-light;
  z-index: 5;
  top: -50px;
  left: calc(50% - 0.5px);
}
.has-vline-short::before{
  height: 30px;
  top: 0;
}
.section-hero{
  position: relative;
  padding: 100px 20px;
  h3{
    text-transform: uppercase;
    font-weight: bold;
    &:after{
      content: '';
      height: 2px;
      width: 66px;
      background: $grey-dark;
      display: block;
      margin-top: 20px;
      margin-left: calc(50% - 33px);
    }
  }
}
.section{
  position: relative;
  padding: 100px 20px;
  h4{
    text-transform: uppercase;
    font-weight: bold;
    &:after{
      content: '';
      height: 2px;
      width: 66px;
      background: $grey-dark;
      display: block;
      margin-top: 20px;
    }
    i{
      display: block;
      font-style: normal;
      text-transform: capitalize;
    }
  }
}
.section-person{
  &-image{
    figure{
      position: relative;
      top: 0;
      display: inline-block;
      height: 200px;
      width: 200px;
      overflow: hidden;
      box-shadow: $shadow-depth-1;
      transition: all 0.4s ease;
      border-radius: 3px;
      &:hover{
        top: -5px;
        box-shadow: $shadow-depth-3;
      }
      img{
        height: 100%;
        width: auto;
        transform: scale(1.03);
        border-radius: 12px;
      }
    }
  }
  .img-left{
    text-align: right;
    padding-right: 50px;
    order: 1;
  }
  .img-right{
    text-align: left;
    padding-left: 50px;
    order: 3;
  }
  .has-text-right h4:after{
    margin-left: calc(100% - 66px);
  }
  .content{
    order: 2;
  }
  @include mobile {
    text-align: center;
    .img-left, .img-right{
      text-align: center;
      padding-right: 0;
      padding-left: 0;
    }
    h4:after{
      margin-left: calc(50% - 33px);
    }
    .has-text-right{
      text-align: center;
      h4:after{
        margin-left: calc(50% - 33px);
      }
    }
  }
}
.image-rd-sh img{
  box-shadow: $shadow-depth-1;
  border-radius: 3px;
}
.section-list{
  .content:not(:last-child){
    margin-bottom: 0;
  }
  .is-block{
    margin-bottom: 2em !important;
    ul{
      margin-top: 0;
    }
  }
  .is-infoheader{
    margin-top: 0;
    margin-bottom: 0.3em;
    font-size: 0.81em;
    color: $grey-light;
  }
  .is-primary{
    color: $text-strong;
  }
  ul{
    margin: 0.25em 0 0 1.2em;
  }
}
.article{
  position: relative;
  top: 0;
  box-shadow: $shadow-depth-1;
  transition: box-shadow 0.4s ease, top 0.4s ease;
  margin: 5px;
  padding-bottom: 10px;
  align-self: flex-start;
  &:hover{
    top: -5px;
    box-shadow: $shadow-depth-3;
  }
  @include tablet{
    &:nth-child(2n+1){
      margin-left: -10px;
    }
    &:nth-child(2n+2){
      margin-right: -10px;
    }
  }
  @include desktop{
    &:nth-child(2n+1){
      margin-left: 5px;
    }
    &:nth-child(2n+2){
      margin-right: 5px;
    }
    &:nth-child(3n+1){
      margin-left: -10px;
    }
    &:nth-child(3n+3){
      margin-right: -10px;
    }
  }
  h4{
    padding-top: 20px;
    text-transform:inherit;
    word-wrap: break-word;
    &::after{
      background: $grey-lighter;
    }
    .article__link{
      color: $primary-color;
      text-decoration: none;
      border: 0;
      outline: none;
      &:visited{
        color: darken($primary-color, 10%);
      }
      &:hover{
        color: lighten($primary-color, 5%);
      }
    }
  }
  &__date{
    position:absolute;
    top:0;
    z-index:10;
    background:#fbfbfb;
    padding:0 5px 5px 0;
    font-size:0.81em;
    border-bottom-right-radius:3px;
  }
  .button{
    margin-top: 20px;
    width: 100%;
  }

  &__zoom{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(0,0,0,0.6);
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.3s ease;
    will-change: opacity;

    img{
      position: absolute;
      left: 50%; top: 50%;
      margin-left: -10px;
      margin-top: -10px;
      transition: opacity 0.6s ease;
      will-change: opacity;
    }

    &:hover{
      opacity: 1;
      img{
        opacity: 1;
      }
    }
  }
}
.section-article-detail{
  h4{
    text-transform: inherit;
  }
}
.old-ie-browser .old-browser-modal{
  display: block;
}
.old-browser-modal{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0; right: 0;
  z-index: 8;
  padding: 30px 100px;
  @include mobile {
    padding: 30px 30px;
  }
  background: $grey-dark;
  color: $grey-light;
  h4{
    color: $warning;
    margin-bottom: 0.25em !important;
    font-weight: 500;
  }
  a{
    color: $white;
  }
  .delete{
    position: absolute;
    top: 30px; right: 30px;
  }
}
.article-detail-img-cont{
  margin: 0 0 20px 4%;
  width: 48%;
  float: right;
  @media screen and (max-width: 569px){
    width: 100%;
  }
}
.article-detail-img{
  border-radius: 3px;
  box-shadow: $shadow-depth-1;
}
.article--img{
  position: relative;
  display: inline-block;
  border: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  border-radius:3px;
  box-shadow: $shadow-depth-1;
}
