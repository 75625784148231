.hero--nav-pad{
  padding-top: 54px;
  @media only screen and (min-width: 769px) {
    padding-top: 67px;
  }
}

#sesosmenu{
  position: fixed;
  top: 0; left: 0; right: 0;
  overflow: auto;
  max-height: 100vh;
  box-shadow: $shadow-depth-3;
  z-index: 10;
}
#sesosmenu #menu{
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  background: $white;
  .main-menu > li:not(:last-child)::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 3px;
    background-color: $grey-lighter;
  }
}
#sesosmenu #menu-line{
  position: absolute;
  top: 0; left: 0;
  height: 3px;
  background: $primary;
  z-index: 10;
  transition: all 0.4s ease;
  display: none;
}
#sesosmenu #menu-button {
  display: block;
  padding: 20px;
  color: $black;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}
#sesosmenu #menu-button::after {
  content: '';
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 15px;
  height: 2px;
  background: $black;
}
#sesosmenu #menu-button::before {
  content: '';
  position: absolute;
  top: 25px;
  right: 20px;
  display: block;
  width: 15px;
  height: 7px;
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
}
#sesosmenu #menu ul {
  margin: 0;
  padding: 0;
}
#sesosmenu #menu .link-span{
  display: none;
  opacity: 0.4;
  padding-bottom: 3px;
  font-size: 10px;
}
#sesosmenu #menu .main-menu {
  display: none;
}
#sesosmenu #tm:checked + .main-menu {
  display: block;
}
#sesosmenu #menu input[type="checkbox"]{
  display: none;
}
#sesosmenu #menu li,
#sesosmenu #toggle-menu,
#sesosmenu #menu .sub-menu {
  border-style: solid;
  border-color: rgba(0, 0, 0, .05);
}
#sesosmenu #menu li,
#sesosmenu #toggle-menu {
  border-width: 0 0 1px;
}
#sesosmenu #menu .sub-menu {
  li a{
    padding: 20px 20px 20px 30px;
  }
  background-color: $white;
  border-width: 1px 0 0;
  margin: 0;
}
#sesosmenu #menu .sub-menu li:last-child {
  border-width: 0;
}
#sesomenu #menu .has-sub-icon ~ input[type="checkbox"]:checked{
  margin: 40px;
}
#sesosmenu #menu li,
#sesosmenu #toggle-menu,
#sesosmenu #menu a {
  position: relative;
  display: block;
  color: $black;
}
#sesosmenu #menu a {
  padding: 20px;
  text-transform: uppercase;
}
#sesosmenu #menu a {
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}
#sesosmenu #menu li:hover .main-menu-link{
  background-color: $white;
  color: $primary;
}
#sesosmenu #menu .sub-menu {
  display: none;
  a{
    text-transform: capitalize;
  }
}
#sesosmenu #menu input[type="checkbox"]:checked + .sub-menu {
  display: block;
}
#sesosmenu #menu .sub-menu a:hover {
  color: #444;
}
#sesosmenu #menu .has-sub-icon{
  &::after{
    position: absolute;
    top: 35px;
    right: 10px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid #0a0a0a;
    border-right: 1px solid #0a0a0a;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
  }
  &:hover::after{
    border-bottom-color: $primary;
    border-right-color: $primary;
  }
}
#sesosmenu #menu .active{
  a{
    color: $primary;
  }
  .sub-menu a{
    color: $black;
  }
}
#sesosmenu #menu .active .has-sub-icon{
  &::after, &::before{
    border-bottom-color: $primary;
    border-right-color: $primary;
  }
}
#sesosmenu .submenu-button{
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  border-left: 1px solid rgba(120, 120, 120, 0.15);
  height: 54px;
  width: 54px;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 21px;
    left: 26px;
    display: block;
    width: 1px;
    height: 11px;
    background: $black;
    z-index: 99;
  }
  &::before {
    content: '';
    position: absolute;
    left: 21px;
    top: 26px;
    display: block;
    width: 11px;
    height: 1px;
    background: $black;
    z-index: 99;
  }
  &-minus::after{
    display: none;
  }
}
#sesosmenu #menu input[type="checkbox"]:checked + .has-sub-icon .submenu-button::after {
  display: none;
}
#sesosmenu #menu .has-sub-icon::after{
  display: none;
}
#sesosmenu .has-sub-label{
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
}
#sesosmenu #menu .sub-sub-menu li a{
  padding-left: 40px;
}
#sesosmenu #menu .has-sub .sub-menu li{
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}
@media only screen and (min-width: 769px) {
  #sesosmenu{
    max-height:none;
    overflow:visible;
  }
  #sesosmenu #menu .main-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  #sesosmenu #menu-line{
    display: block;
  }
  #sesosmenu #tm:checked + .main-menu{
    display: flex;
  }
  #sesosmenu #menu .link-span{
    display: block;
  }
  #sesosmenu #menu .has-sub-icon::after{
    display: block;
  }
  #sesosmenu #menu .active .sub-menu a{
    color: rgba($white, 0.7);
  }
  #sesosmenu #menu a {
    padding: 20px 9px;
  }
  #sesosmenu .has-sub-label{
    display: none;
  }
  #sesosmenu #toggle-menu{
    display: none;
  }
  #sesosmenu #menu li {
    float: left;
    flex-grow: 1;
  }
  #sesosmenu #menu li,
  #sesosmenu #toggle-menu {
    border-width: 0 0 0;
  }
  #sesosmenu #menu .sub-menu li {
    float: none;
  }
  #sesosmenu #menu .sub-menu li a{
    text-transform: initial;
    padding: 10px 20px;
    width: 190px;
    font-size: 0.81rem;
    background: rgba(38, 38, 38, 1);
    text-decoration: none;
    color: rgba($white, 0.7);
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    &:hover{
      color: rgba($white, 1);
    }
  }

  #sesosmenu #menu .sub-menu{
    border-width: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3000;
    box-shadow: $shadow-depth-4;
    transition: all 0.2s ease;
  }
  #sesosmenu #menu .sub-menu.sub-menu-right{
    right: 0;
    left: initial;
  }
  #sesosmenu #menu .sub-menu,
  #sesosmenu #menu input[type="checkbox"]:checked + .sub-menu {
    // display: hidden;
    display: block;
    visibility: hidden;
    opacity: 0;
  }

  #sesosmenu #menu .has-sub .sub-menu li{
    height: 0;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
  }
  #sesosmenu #menu .has-sub:hover .sub-menu li{
    height: 32px;
  }
  #sesosmenu #menu .has-sub:hover .sub-menu li:first-child,
  #sesosmenu #menu .has-sub:hover .sub-menu li:last-child{
    height: 42px;
  }
  #sesosmenu #menu .has-sub .sub-menu li:first-child > a{
    padding-top: 20px;
  }
  #sesosmenu #menu .has-sub .sub-menu li:last-child > a{
    padding-bottom: 20px;
  }

  #sesosmenu #menu .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }
  #sesosmenu #menu .sub-menu-right .sub-menu-right{
    top: 0;
    right: 100% !important;
    left: initial;
  }

  #sesosmenu #menu li:hover > input[type="checkbox"] + .sub-menu {
    // display: block;
    visibility: visible;
    opacity: 1;
  }
  #sesosmenu #menu .has-sub-sub-menu{
    &::after{
      position: absolute;
      top: 13px;
      right: 10px;
      width: 4px;
      height: 4px;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      content: "";
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition: border-color 0.2s ease;
      -moz-transition: border-color 0.2s ease;
      -ms-transition: border-color 0.2s ease;
      -o-transition: border-color 0.2s ease;
      transition: border-color 0.2s ease;
    }
    &:hover::after{
      border-bottom-color: $white;
      border-right-color: $white;
    }
  }
}
@media only screen and (min-width: 900px) {
  #sesosmenu #menu a {
    padding: 20px;
  }
}

@media only screen and (min-width: 1023px) {
  #sesosmenu #menu a {
    padding: 20px 30px;
  }
  #sesosmenu #menu .has-sub-icon::after{
    right: 15px;
  }
}
