// Variables
@import "../variables";

// Colors
$primary-color: #f0414a;
$black-color: #020500;
$white-color: #f8fff2;

// Rest
@import "../frontend";
